<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div class="headline">Benutzerzuordnung Lizenz</div>
        </v-card-title>
        <v-card-text>
          <div>Lizenznummer: {{licence.id}}</div>
          <div>Kunde: {{licence.owner.email}}</div>
          <div>Produkt: {{licence.product.name}}</div>

          <div>Lizenzen: {{quantityTotal}}</div>
          <div>Verfügbare Lizenzen: {{quantityRemaining}}</div>
          <v-form ref="productForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="text-h5 mb-2">Zuordnung</div>
                  <user-select
                      :value.sync="assignments"
                      :users="user && user.role === 'admin' ? users : teamMembers"
                      label="Teilnehmer"
                      multiple
                      @removeItem="removeUser"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveProduct"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
    <v-snackbar
        v-model="saveSuccess"
        color="success"
        multi-line="multi-line"
        timeout="2000"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import UserSelect from '@/components/UserSelect'
import Snackbar from "@/components/Snackbar";

const LICENCE_INIT_DATA = {
  assignments: [],
  product: {},
  owner: {}
}
export default {
  name: 'LicenceAssignDialog',
  components: {Snackbar, ConfirmDialog, UserSelect },
  props: ['userId'],
  data() {
    return {
      quantityTotal: 0,
      assignments: [],
      teamMembers: [],
      showDialog: false,
      values: [],
      value: null,
      licence: LICENCE_INIT_DATA,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: 'Lizenzzuordnung erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
    }
  },
  methods: {
    async edit(licence) {
      this.licence = licence
      this.quantityTotal = licence.quantityTotal
      if(this.user.role === 'user') {
        this.teamMembers = await APIService.getTeamMembers( this.userId)
      }
      this.assignments = await APIService.getAssignments(licence.id)
      this.showDialog = true
    },
    close() {
      this.licence = LICENCE_INIT_DATA
      this.$refs.productForm.resetValidation()
      this.showDialog = false
    },
    async saveProduct () {
      if (!this.$refs.productForm.validate()) {
        return
      }
      // if(!this.product.id && await APIService.isProductNameTaken(this.product.name, this.product.instance)) {
      //   this.isProductNameTaken = true
      //   return
      // }
      await APIService.updateAssignments(this.licence.id, this.assignments)
      this.showDialog = false
      this.$emit('updated')
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.close()
    },
    removeUser(item) {
      const index = this.assignments.indexOf(item.id)
      if (index >= 0) this.assignments.splice(index, 1)
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['users', 'user']),
    quantityRemaining() {
      return this.quantityTotal - this.assignments.length
    }
  },
  watch: {
    "assignments"() {
        if(this.quantityRemaining < 0) {
          this.assignments.pop()
        }
    }
  }
}
</script>

<style scoped>

</style>
