<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Lizenz {{licence.id ? 'bearbeiten' : 'erstellen'  }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="productForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-if="!licence.id"
                      v-model="licence.product"
                      :items="products"
                      outlined
                      label="Produkte"
                      item-text="name"
                      item-value="id"
                      :filter="productFilter"
                      :rules="requiredRule('Produkt')"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.number"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <div v-if="licence.id">
                    <div class="text-h6">Produkt</div>
                    {{licence.product.name}}
                  </div>
                </v-col>
                <v-col cols="12">
                  <user-select
                      v-if="!licence.id"
                      :value.sync="licence.owner"
                      :users="users"
                      label="Kunde"
                      :rules="requiredRule('Kunde')"
                  />
                  <div v-if="licence.id">
                    <div class="text-h6">Kunde</div>
                    <div>{{licence.owner.firstName}} {{licence.owner.lastName}}</div>
                    <div v-if="licence.owner.companyName">{{licence.owner.companyName}}</div>
                    <div v-if="licence.owner.customerNumber">{{licence.owner.customerNumber}}</div>
                    <div>{{licence.owner.email}}</div>
                    <div class="mt-5">Kaufdatum: {{licence.dateOfPurchase | formatLocaleDate}}</div>
                    <div>Verfügbare Lizenzen: {{licence.quantityRemaining}}</div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="licence.orderNumber"
                      label="Auftragsnummer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model.number="licence.quantityTotal"
                      label="Anzahl Lizenzen"
                      :rules="requiredRule('Anzahl')"
                      @change="validateLicenceQuantity($event)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <date-picker label="Startdatum (Optional)" v-model="licence.startDate"/>
                </v-col>
                <v-col cols="12">
                  <date-picker label="Ablaufdatum (Optional)" v-model="licence.expirationDate"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      label="Kommentar"
                      v-model="licence.comment"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveLicence"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";
import DatePicker from "@/components/DatePicker";
import { propertySearch } from "@/utils/searchUtils";
import UserSelect from "@/components/UserSelect";
const GROUP_INIT_DATA = {
  product: {},
  owner: {},
}
export default {
  name: 'LicenceEditDialog',
  components: {UserSelect, DatePicker, Snackbar, ConfirmDialog },
  data() {
    return {
      products: [],
      showDialog: false,
      values: [],
      value: null,
      licence: GROUP_INIT_DATA,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      quantityRequired: null,
      snackbarSuccess: {
        text: 'Lizenz erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
    }
  },
  methods: {
    async create() {
      this.licence = { ...GROUP_INIT_DATA }
      this.products = await APIService.getProducts()
      this.showDialog = true
    },
    async edit(licence) {
      this.licence = licence
      this.quantityRequired = licence.quantityTotal - licence.quantityRemaining
      this.products = await APIService.getProducts()
      this.showDialog = true
    },
    close() {
      this.licence = { ...GROUP_INIT_DATA }
      this.$refs.productForm.resetValidation()
      this.showDialog = false
    },
    productFilter(product, query) {
      return propertySearch(product, query, ['name', 'number'])
    },
    async saveLicence () {
      if (!this.$refs.productForm.validate()) {
        return
      }
      // if(!this.product.id && await APIService.isProductNameTaken(this.product.name, this.product.instance)) {
      //   this.isProductNameTaken = true
      //   return
      // }
      await APIService.saveLicence(this.licence)
      this.showDialog = false
      this.$emit('updated')
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.close()
    },
    requiredRule (fieldName) {
      return [value => {
        console.log(fieldName, typeof value , value)
        return (value && typeof value !=="object" )  || `${fieldName} erforderlich.`
      }]
    },
    userFilter(user, query) {
      return propertySearch(user, query, ['firstName','lastName', 'email','companyName'])
    },
    validateLicenceQuantity() {
      if(this.quantityRequired > this.licence.quantityTotal) {
        this.licence.quantityTotal = this.quantityRequired
      }
    }
  },
  computed: {
    ...mapState(['users'])
  },
}
</script>

<style scoped>

</style>
