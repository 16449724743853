<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Produkt</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="productForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div v-if="product.id" class="product-info">
                    <div v-if="product.createdAt">
                      Erstellt am:
                      {{product.createdAt | formatLocaleDate}}
                    </div>
                    <div v-if="product.author">
                      Erstellt von:
                      {{product.author.firstName}} {{product.author.lastName}}
                    </div>
                    <div v-if="product.lastUpdatedBy">
                      Zuletzt bearbeitet von:
                      {{product.lastUpdatedBy.firstName}} {{product.lastUpdatedBy.lastName}}
                    </div>
                    <div v-if="product.updatedAt">
                      Zuletzt bearbeitet am:
                      {{product.updatedAt | formatLocaleDate}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="product.name"
                      label="Produktname"
                      required
                      :rules="productNameRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="product.number"
                      label="Artikelnummer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="product.articleGroup"
                      label="Artikelgruppe"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="product.description"
                      label="Beschreibung"
                  ></v-text-field>
                </v-col>
                <v-col class="mb-0 pb-0" cols="12">
                  <div class="text-h5 mb-2">Enthaltene Kurse</div>
                  <v-autocomplete
                      v-model="product.courses"
                      :items="courses"
                      outlined
                      chips
                      small-chips
                      label="Kurse"
                      item
                      multiple
                      item-text="title"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      v-model="product.comment"
                      label="Kommentar"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveProduct"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";
const GROUP_INIT_DATA = {
  courses: [],
}
export default {
  name: 'ProductEditDialog',
  components: {Snackbar, ConfirmDialog },
  data() {
    return {
      courses: [],
      showDialog: false,
      values: [],
      value: null,
      product: GROUP_INIT_DATA,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: 'Produkt erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
    }
  },
  methods: {
    async create(instance) {
      this.product = { ...GROUP_INIT_DATA, instance }
      this.courses = await APIService.getCourses()
      this.showDialog = true
    },
    async edit(product) {
      this.product = product
      this.courses = await APIService.getCourses()
      this.showDialog = true
    },
    close() {
      this.product = { ...GROUP_INIT_DATA }
      this.$refs.productForm.resetValidation()
      this.showDialog = false
    },
    async saveProduct () {
      if (!this.$refs.productForm.validate()) {
        return
      }
      // if(!this.product.id && await APIService.isProductNameTaken(this.product.name, this.product.instance)) {
      //   this.isProductNameTaken = true
      //   return
      // }
      await APIService.saveProduct(this.product)
      this.showDialog = false
      this.$emit('updated')
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.close()
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    productNameRules() {
      return [
        value => !!value || 'Produktname erforderlich.',
        !this.isProductNameTaken || 'Der Produktname ist bereits vergeben.'
      ]
    },
  },
  computed: {
    ...mapState(['users'])
  },
  watch: {
    "product.name"() {
      this.isProductNameTaken = false
    }
  }
}
</script>

<style scoped>

</style>
